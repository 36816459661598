var value,
  quantity = document.getElementsByClassName("quantityInput");

function createBindings(quantityContainer) {
  var quantityAmount = quantityContainer.getElementsByClassName(
    "quantityInput-amount"
  )[0];
  var increase = quantityContainer.getElementsByClassName(
    "quantityInput-increase"
  )[0];
  var decrease = quantityContainer.getElementsByClassName(
    "quantityInput-decrease"
  )[0];
  increase.addEventListener("click", function() {
    increaseValue(quantityAmount);
  });
  decrease.addEventListener("click", function() {
    decreaseValue(quantityAmount);
  });
}

function init() {
  for (var i = 0; i < quantity.length; i++) {
    createBindings(quantity[i]);
  }
}

function increaseValue(quantityAmount) {
  value = parseInt(quantityAmount.value, 10);

  // console.log(quantityAmount, quantityAmount.value);

  value = isNaN(value) ? 0 : value;
  value++;
  quantityAmount.value = value;
}

function decreaseValue(quantityAmount) {
  value = parseInt(quantityAmount.value, 10);

  value = isNaN(value) ? 0 : value;
  if (value > 0) value--;

  quantityAmount.value = value;
}

init();
