import Swiper from "swiper";

const defaultSliderOptions = {
  slidesPerView: 1,
  spaceBetween: 10,
  speed: 500,
  breakpoints: {
    1170: {
      slidesPerView: 5
    },
    800: {
      slidesPerView: 4
    },
    600: {
      slidesPerView: 3
    },
    390: {
      slidesPerView: 2
    },
    300: {
      slidesPerView: 2
    }
  }
};

const defaultSliders = document.querySelectorAll(".defaultSlider-js");
if (defaultSliders) {
  defaultSliders.forEach(slider => {
    if (slider.classList.contains("is-2")) {
      new Swiper(slider.querySelector(".swiper-container"), {
        slidesPerView: 2,
        spaceBetween: 10,
        speed: 500,
        breakpoints: {
          992: {
            slidesPerView: 2
          },
          575: {
            slidesPerView: 1
          }
        },
        navigation: {
          nextEl: slider.querySelector(".defaultSlider-js-next"),
          prevEl: slider.querySelector(".defaultSlider-js-prev")
        }
      });
    } else {
      new Swiper(slider.querySelector(".swiper-container"), {
        ...defaultSliderOptions,
        navigation: {
          nextEl: slider.querySelector(".defaultSlider-js-next"),
          prevEl: slider.querySelector(".defaultSlider-js-prev")
        }
      });
    }
  });
}
