import "intersection-observer";
import Headroom from "headroom.js";
import Choices from "choices.js";
import noUiSlider from "nouislider";
import Modal from "bootstrap.native/dist/bootstrap-native-v4";
import "./sliders.js";
import "./vanillatop";
import "./quantity";

// loadClass
document.getElementById("theApp").classList.add("is-loaded");

// Sticky Header
const theHeader = document.getElementById("theHeader");

var headroom = new Headroom(theHeader, {
  offset: 100,
  classes: {
    initial: "is-sticky",
    // when scrolling up
    pinned: "is-pinned",
    // when scrolling down
    unpinned: "is-unpinned",
    // when above offset
    top: "is-top",
    // when below offset
    notTop: "is-not-top",
    // when at bottom of scoll area
    bottom: "is-bottom",
    // when not at bottom of scroll area
    notBottom: "is-not-bottom"
  }
});
headroom.init();

// #navOverlay
const navOverlay = document.querySelectorAll(".navOverlay")[0];
const navOverlayClose = document.querySelectorAll(".navOverlay-close");
const navOverlayOpen = document.querySelectorAll(".navOverlay-open");

if (navOverlayClose && navOverlay) {
  navOverlayClose.forEach(el => {
    el.addEventListener("click", event => {
      navOverlay.classList.remove("is-active");
      // navOverlayOpen[0].classList.remove("is-active");
    });
  });
}

if (navOverlayOpen && navOverlay) {
  navOverlayOpen.forEach(el => {
    el.addEventListener("click", event => {
      if (navOverlay.classList.contains("is-active")) {
        navOverlay.classList.remove("is-active");
        navOverlayOpen[0].classList.remove("is-active");
      } else {
        navOverlay.classList.add("is-active");
        navOverlayOpen[0].classList.add("is-active");
      }
    });
  });
}

// lax/
window.addEventListener("load", event => {
  // Observer
  const inViewElms = document.querySelectorAll(".inViewJs");

  const observer = new IntersectionObserver(entries => {
    entries.forEach(
      entry => {
        const lazyImage = entry.target.querySelector("img[data-src]");

        if (entry.intersectionRatio > 0) {
          // console.log(lazyImage.querySelector("img[data-src]"));
          entry.target.classList.add("inViewJs-active");
          lazyImage.src = lazyImage.dataset.src;
          observer.unobserve(entry.target);
        } else {
          // console.log("out of view");
          // entry.target.classList.remove("inViewJs-active");
        }
      },

      {
        rootMargin: "0px 0px 100px 0px"
      }
    );
  });

  inViewElms.forEach(elm => {
    observer.observe(elm);
  });

  // Tabs
  // store tabs variable
  var myTabs = document.querySelectorAll("ul.tabs > li ");
  function myTabClicks(tabClickEvent) {
    for (var i = 0; i < myTabs.length; i++) {
      myTabs[i].classList.remove("active");
    }
    var clickedTab = tabClickEvent.currentTarget;
    clickedTab.classList.add("active");
    tabClickEvent.preventDefault();
    var myContentPanes = document.querySelectorAll(".tab-pane");
    for (i = 0; i < myContentPanes.length; i++) {
      myContentPanes[i].classList.remove("active");
    }
    var anchorReference = tabClickEvent.target;
    var activePaneId = anchorReference.getAttribute("href");
    var activePane = document.querySelector(activePaneId);
    activePane.classList.add("active");
  }
  for (var i = 0; i < myTabs.length; i++) {
    myTabs[i].addEventListener("click", myTabClicks);
  }
});

// viewToggle
const viewToggle = document.getElementById("viewToggle");

if (viewToggle) {
  const viewToggle_grid = document.getElementById("viewToggle-grid");
  const viewToggle_list = document.getElementById("viewToggle-list");
  const viewToggle_container = document.getElementById("viewToggle-container");
  const viewToggle_container_cards = viewToggle_container.querySelectorAll(
    ".appCard"
  );

  viewToggle_list.addEventListener("click", event => {
    viewToggle_list.classList.add("is-active");
    viewToggle_grid.classList.remove("is-active");
    viewToggle_container.classList.add("is-list");

    viewToggle_container_cards.forEach(card => {
      card.classList.add("is-wide");
    });
  });

  viewToggle_grid.addEventListener("click", event => {
    viewToggle_grid.classList.add("is-active");
    viewToggle_list.classList.remove("is-active");
    viewToggle_container.classList.remove("is-list");

    viewToggle_container_cards.forEach(card => {
      card.classList.remove("is-wide");
    });
  });
}

// choices
const defaultChoices = document.querySelectorAll(".choice-js");

defaultChoices.forEach(element => {
  new Choices(element);
});

const multipleChoices = document.querySelectorAll(".choice-m-js");
multipleChoices.forEach(element => {
  new Choices(element, {
    delimiter: ",",
    editItems: true,
    maxItemCount: 5,
    removeItemButton: true
  });
});

// SideBar

const sidebarItems = document.querySelectorAll(".sidebar-nav-header");

if (sidebarItems) {
  sidebarItems.forEach(el => {
    el.addEventListener("click", event => {
      event.target.closest(".sidebar-nav-item").classList.toggle("is-active");
    });
  });
}

// #Search

const headerSearchToggle = document.getElementById("search-toggle");
const headerSearch = document.querySelectorAll(".theHeader-search")[0];

if (headerSearchToggle && headerSearch) {
  headerSearchToggle.addEventListener("click", event => {
    headerSearch.classList.toggle("is-active");
  });
}

// Tabs

// noUiSlider
const priceSlider = document.getElementById("priceSlider");
if (priceSlider) {
  noUiSlider.create(priceSlider, {
    start: [20, 80],
    connect: true,
    tooltips: [true, true],

    range: {
      min: 0,
      max: 100
    }
  });
}
const priceFilter = document.getElementById("priceFilter");
if (priceFilter) {
  noUiSlider.create(priceFilter, {
    start: [20, 80],
    connect: true,
    tooltips: [true, true],

    range: {
      min: 0,
      max: 100
    }
  });
}
const yearSlider = document.getElementById("yearSlider");
if (yearSlider) {
  noUiSlider.create(yearSlider, {
    start: [30, 70],
    connect: true,
    tooltips: [true, true],

    range: {
      min: 0,
      max: 100
    }
  });
}
